@import 'styles';

:root:root:root {
    .setter-mobile-controls {
        display: flex;
        flex-direction: column;
        padding: $sp-12;

        &__buttons {
            display: flex;
            justify-content: space-between;

            > .button {
                margin: 0 $sp-4;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}
