.control-columns {
    @media (max-width: 760px) {
        flex-direction: column-reverse;
        margin: 0;
        > .column {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

.menu {
    transform: none;
}

// spectre's btn-link doesn't match the
// link styles - so use this instead
.btn-fake-link {
    border-radius: 0.1rem;
    padding: 0.2rem 0.4rem;
    border: none;
    background: none;
    margin: 0 -0.4rem;
    display: block;
    color: inherit;

    width: calc(100% + 0.8rem);
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;

    transition: 0.15s;

    &:focus {
        outline: none;
    }

    &:not(:disabled) {
        &:focus,
        &:hover {
            background: $purple-100;
            color: $purple-600;
        }

        &:active {
            color: $purple-600;
            background: $purple-200;
        }
    }

    &:disabled {
        cursor: default;
        color: $grey-400;
    }
}

.btn.btn {
    &-light {
        background: $purple-100;
        border: 2px solid transparent;

        &:hover,
        &:focus {
            color: $purple-600;
            background: $purple-200;
        }

        &:active {
            background: $purple-300;
        }

        &.btn-active {
            border: 2px solid $purple-600;
        }

        &.btn-error {
            border: 2px solid transparent;
            background: $red-100;
            color: $red-600 !important;

            &:hover,
            &:focus {
                background: $red-200;
            }

            &:active {
                background: $red-300;
            }
        }

        &.btn-success {
            border: 2px solid transparent;
            background: $green-100;
            color: $green-600 !important;

            &:hover,
            &:focus {
                background: $green-200;
            }

            &:active {
                background: $green-300;
            }
        }
    }
}

.form-checkbox,
.form-switch,
.form-radio {
    cursor: pointer;
    padding: 0.2rem 0.4rem;
    padding-left: 1.6rem;
    border-radius: 2px;

    .form-icon {
        margin-left: 0.4rem;
        margin-top: 0.1rem;
    }
}

.menu .menu-item {
    .form-switch,
    .form-checkbox {
        margin: 0 -0.4rem;
    }
}

.form-switch {
    padding-left: 2.4rem;
}

.fa {
    width: 16px;
    display: inline-flex;
    justify-content: center;
}
