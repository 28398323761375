@use 'sass:math';
@import 'styles';

.background {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;

    background: $cell-bg;

    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;

    &--diagonals {
        &::before {
            content: '';
            width: 100% * sqrt(2);
            height: 8px;
            background: $blue-100;
            position: absolute;
            left: 0;
            top: 0;
            transform-origin: 0 50%;
            transform: translateY(-50%) rotate(45deg);
            z-index: -1;
        }

        &::after {
            content: '';
            width: 100% * sqrt(2);
            height: 8px;
            background: $blue-100;
            position: absolute;
            left: 0;
            bottom: 0;
            transform-origin: 0 50%;
            transform: translateY(50%) rotate(-45deg);
            z-index: -1;
        }
    }
}

.background-cell {
    position: relative;
    border: 1px solid transparent;

    &--yellow {
        background: $yellow-100-2;
    }

    &--red {
        background: $red-100-2;
    }

    &--green {
        background: $green-100-2;
    }

    &--blue {
        background: $blue-100-2;
    }
}

.theme-dark {
    .background {
        background: $cell-bg-dark;
    }

    .background-cell {
        &--yellow {
            background: $yellow-100-3;
        }

        &--red {
            background: $red-100-3;
        }

        &--green {
            background: $green-100-3;
        }

        &--blue {
            background: $blue-100-3;
        }
    }
}

// svg

@function radial-outline($width, $color, $iters: 4) {
    $output: unquote('');
    @for $i from 1 through $iters {
        $angle: 2 * math.$pi * $i / $iters;
        @debug $angle;
        $y: $width * math.sin($angle);
        $x: $width * math.cos($angle);
        $output: $output drop-shadow($x $y $color);
    }
    @return $output;
}

.background-svg {
    dominant-baseline: hanging;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    g {
        filter: radial-outline(2px, white);
    }
}

.theme-dark .background-svg g {
    filter: radial-outline(2px, $black);
}

.svg-thermo {
    fill: $grey-400;
    stroke: $grey-400;

    &--ghost {
        fill: $grey-300;
        stroke: $grey-300;
    }
}
.theme-dark .svg-thermo {
    fill: $grey-600;
    stroke: $grey-600;

    &--ghost {
        fill: $grey-500;
        stroke: $grey-500;
    }
}

.svg-arrow {
    fill: white;
    stroke: $grey-300;
}
.theme-dark .svg-arrow {
    fill: $black;
    stroke: $grey-400;
}

.svg-cage {
    path {
        stroke: $grey-700;
        stroke-width: 3;
        stroke-dasharray: 6;
    }

    text {
        fill: $grey-700;
        background: white;
        font-size: 20px;
        font-weight: bold;
    }
}

.theme-dark .svg-cage {
    path {
        stroke: $grey-500;
    }

    text {
        fill: $grey-300;
    }
}
