@import 'styles';

.box {
    box-shadow: 0 0 0 2px $cell-outline;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.theme-dark .box {
    box-shadow: 0 0 0 2px $cell-outline-dark;
}
