body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.app {
    flex: 1 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding-top: $sp-24;

    background: linear-gradient($grey-100, $grey-200);

    @include mobile {
        align-items: flex-start;
    }
}

.theme-dark .app {
    background: $black;
}
