@import 'styles';

:root:root {
    .modal {
        .modal-overlay {
            background: rgba(0, 0, 0, 0.2);
        }

        .modal-container {
            padding: 0;
            border-radius: 4px;
            border: 1px solid $grey-300;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        }

        .modal-body {
            padding: $sp-12;
        }

        .modal-body > .menu {
            border: none;
            margin: -#{$sp-12};
        }

        .modal-header {
            background: $grey-100;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid $grey-300;

            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }

        .modal-title {
            font-weight: bold;
        }

        .modal-footer {
            border-top: 1px solid $grey-300;
            background: $grey-100;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .theme-dark .modal {
        .modal-overlay {
            background: rgba(0, 0, 0, 0.4);
        }

        .modal-container {
            background: $grey-900;
            color: white;
            border-color: $black;
        }

        .modal-header {
            background: $black;
            border-color: transparent;
            color: white;
        }

        .modal-footer {
            background: $black;
            border-color: transparent;
        }
    }
}
