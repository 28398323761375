$spacing-scale: 0 1 4 8 12 16 20 24 32 40 48 56 64 72 80;
$spacing-props: margin padding;
$directions: top right bottom left;

$sp-0: 0px;
$sp-1: 1px;
$sp-4: 4px;
$sp-8: 8px;
$sp-12: 12px;
$sp-16: 16px;
$sp-20: 20px;
$sp-24: 24px;
$sp-32: 32px;
$sp-40: 40px;
$sp-48: 48px;
$sp-56: 56px;
$sp-64: 64px;
$sp-72: 72px;
$sp-80: 80px;
