@import 'styles';

:root:root {
    .settings-modal {
        box-shadow: none;

        &__menu {
            border: none;
        }
    }
}
