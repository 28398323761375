@import 'styles';

.number-input {
    display: flex;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 16px;
    flex-wrap: wrap;

    > .button {
        flex: 1 1 calc(20% - 6px);
        margin: 0 2px !important;
        margin-bottom: 4px !important;
        text-align: center !important;
    }
}
