@mixin focus-outline {
    outline: none;
    box-shadow: 0 0 0 2px $grey-500;
}

@mixin mobile {
    @media (max-width: $bp-mobile) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $bp-mobile + 1px) {
        @content;
    }
}
