@import 'styles';

.cell {
    $b: &;
    border: 0.5px solid $cell-outline;
    color: $digit-color;
    background: transparent;
    outline: none;
    padding: 10px;
    position: relative;
    z-index: 0;
    font-size: 64px;
    @include text-white-outline;

    &--filled {
        padding: 0;
    }

    &--highlighted {
        background: $cell-highlight-bg;
    }

    &--matches {
        background: $cell-matched-bg;
    }

    &--selected {
        background: $cell-selected-bg;
        border-color: $cell-selected-bg;
    }

    &--focused {
        z-index: 1;

        &::after {
            $gap: 0px;
            content: '';
            position: absolute;
            left: $gap;
            top: $gap;
            bottom: $gap;
            right: $gap;
            box-shadow: 0 0 0 4px $cell-primary-focus-outline;
            pointer-events: none;
        }
    }

    &--given {
        color: $digit-given-color;
    }

    &--incorrect {
        color: $digit-incorrect-color;
        background: $cell-incorrect-bg;
    }

    &--empty::after {
        z-index: -1;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $cell-incorrect-bg;
    }

    &__candidates {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        height: 100%;
    }

    &__mark {
        $margin: 2px;
        font-size: 24px;
        color: $digit-mark-color;
        @include text-white-outline;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 100%;
        margin: $margin;

        &--invalid {
            color: $digit-incorrect-color !important;
        }
    }

    &__corner-marks,
    &__centre-marks {
        $pad: 12px;
        font-size: 24px;
        position: absolute;
        left: $pad;
        top: $pad;
        width: calc(100% - #{$pad} * 2);
        height: calc(100% - #{$pad} * 2);
    }

    &__corner-marks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 4px;

        span {
            line-height: 18px;
        }

        &--top {
            display: flex;
            justify-content: space-between;
        }

        &--bottom {
            display: flex;
            justify-content: space-between;
            min-height: 18px;
        }
    }

    &__centre-marks {
        $pad: 12px;
        top: calc(20% + #{$pad} / 2);
        height: calc(60% - #{$pad});
        display: flex;
        flex-wrap: wrap;
        line-height: 1;
        gap: 0;
        align-items: center;
        justify-content: center;
    }

    &__num {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 10px;
        padding: 0 2px;
        color: $digit-color;
        background: $cell-debug-bg;
    }
}

.theme-dark .cell {
    border-color: $cell-outline-dark;
    color: $digit-color-dark;
    @include text-dark-outline;

    &--highlighted {
        background: $cell-highlight-bg-dark;
    }

    &--given {
        color: $digit-given-color-dark;
    }

    &--selected {
        background: $cell-selected-bg-dark;
    }

    &--focused::after {
        box-shadow: 0 0 0 4px $cell-primary-focus-outline-dark;
    }

    &--matches {
        background: $cell-matched-bg-dark;
    }

    &--incorrect {
        color: $digit-incorrect-color-dark;
        background: $cell-incorrect-bg-dark;
    }

    &__mark {
        color: $digit-mark-color-dark;
        @include text-dark-outline;
    }

    &__num {
        background: $cell-debug-bg-dark;
    }
}
