@import 'styles';

.color-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__option {
        border: 2px solid $grey-300;
        border-radius: 2px;
        width: 28px;
        height: 28px;
        margin: 8px;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            cursor: pointer;
            border-color: $purple-600;
        }

        &:focus {
            @include focus-outline;
        }

        &--white {
            background: white;
        }

        &--yellow {
            background: $yellow-100;
        }

        &--red {
            background: $red-100;
        }

        &--green {
            background: $green-100;
        }

        &--blue {
            background: $blue-100;
        }

        &--selected {
            border-color: $grey-800;
        }
    }
}

.theme-dark .color-picker {
    &__option {
        border-color: $black;

        &--selected {
            border-color: $grey-200;
        }

        &--white {
            background: $black;
        }
    }
}
