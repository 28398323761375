@import 'styles';

.control-box {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    border: 2px solid $grey-200;
    border-radius: 16px;

    .btn {
        padding: 10px 16px;
        height: 50px;
        max-width: 50px;
        margin: 4px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        i {
            font-size: 16px;
        }

        .flip-horiz {
            transform: scaleX(-1);
        }

        &.alone {
            &:first-child {
                margin-right: 8px;
            }
            &:last-child {
                margin-left: 8px;
            }
        }

        &.centre,
        &.corners {
            font-size: 12px;
        }

        &.corners {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 4px 6px;
            padding-top: 10px;

            > span {
                flex: 0 0 50%;
                line-height: 9px;
            }
        }
    }

    &__section {
        display: flex;

        &:first-child {
            margin-bottom: 8px;
        }
    }

    &__input-mode,
    &__actions {
        flex: 0;

        display: flex;
        flex-direction: column;

        > * {
            flex: 0 1 calc(100% / 3 - 8px);
        }
    }

    &__numbers {
        display: flex;
        flex-wrap: wrap;
        max-width: 174px;
        margin: 0 8px;
    }

    &__number-btn {
        flex: 0 1 calc(100% / 3 - 8px);
    }

    &__colors {
        flex: 1;

        display: flex;
        max-width: 174px;
        margin: 0 8px;
    }
}
