@import 'styles';

:root:root {
    .button {
        border: none;
        border-radius: 4px;
        padding: $sp-4 $sp-12;
        background: transparent;
        margin: 0;
        cursor: pointer;
        display: inline-block;
        width: auto;

        text-decoration: none;

        color: $grey-800;
        border: 2px solid transparent;
        text-align: left;

        transition: 0.1s;

        &:hover {
            background: $grey-200;
            border-color: $grey-300;
        }

        &:focus-visible {
            @include focus-outline;
        }

        &--wide {
            width: 100%;
        }

        &--primary {
            color: $purple-500;
            background: $grey-200;
            font-weight: bold;

            &:hover {
                border-color: $purple-300;
            }
        }

        &--danger {
            color: $red-600;

            &:hover {
                border-color: $red-600;
            }
        }

        &--disabled {
            pointer-events: none;
            color: $grey-500;
        }

        @media (pointer: coarse) {
            padding: $sp-12 $sp-16;
        }
    }

    .theme-dark .button {
        color: white;

        &:hover {
            background: $grey-800;
            border-color: transparent;
        }

        &--primary {
            background: $purple-500;
            color: white;
            font-weight: bold;

            &:hover {
                background: $purple-600;
            }
        }

        &--danger {
            color: $red-600;

            &:hover {
                border-color: $red-600;
            }
        }

        &--disabled {
            color: $grey-600;

            &.button--primary {
                background: $grey-800;
            }
        }
    }
}
