@import 'styles';

// 2x root required to override menu styles
:root:root .context-menu {
    width: min-content;
    min-width: 280px;
    border-radius: 4px;

    position: fixed;
    left: var(--x, -10000px);
    top: var(--y, -10000px);

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s, box-shadow 0.1s;
    box-shadow: 0 0 0 2px $purple-200-2;

    &:hover {
        box-shadow: 0 0 0 4px $purple-200-2;
    }

    &--visible {
        opacity: 1;
        pointer-events: initial;
    }

    &--mobile {
        position: relative;
        left: unset;
        top: unset;
        opacity: 1;
        pointer-events: initial;
        width: 100%;
    }
}
