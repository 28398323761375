@import 'styles';

$extra: 10px;
$padding: $sp-8;

$free-space-required: 300px;

$a: 200px;

$max-size: 800px;

.board-container {
    position: relative;
    border-radius: 4px;
    --scale: 100;
    --mobile-scale: 100;

    width: calc(1000px * var(--scale) / 100 + #{$padding / 2});
    height: calc(1000px * var(--scale) / 100 + #{$padding / 2});

    @include mobile {
        width: calc(1000px * var(--mobile-scale) / 100 + #{$padding / 2});
        height: calc(1000px * var(--mobile-scale) / 100 + #{$padding / 2});
    }

    .board-sizer {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1000px;
        height: 1000px;
        transform: translate(-50%, -50%) scale(calc(var(--scale) / 100));

        @include mobile {
            transform: translate(-50%, -50%)
                scale(calc(var(--mobile-scale) / 100));
        }
    }
}

// .board-container {
//     position: relative;
//     background: $grey-300;
//     --size: 1000px;
//     width: calc(var(--size) + #{$padding});
//     height: calc(var(--size) + #{$padding});
//     border-radius: 4px;

//     @for $i from 1 through 50 {
//         $j: 10 - ($i / 5);
//         @media (max-width: $j * 120px + $extra) {
//             --size: #{($j - 1) * 120px};
//         }
//     }

//     .board-sizer {
//         position: absolute;
//         left: 50%;
//         top: 50%;

//         transform: translate(-50%, -50%) scale(0.9);
//         width: 1000px;
//         height: 1000px;
//         border: 2px solid $grey-300;

//         @for $i from 1 through 50 {
//             $j: 10 - ($i / 5);
//             $scale: ($j - 2) / 10;
//             @media (max-width: $j * 120px + $extra) {
//                 transform: translate(-50%, -50%) scale($scale);
//             }
//         }
//     }
// }

.theme-dark .board-container {
    background: $cell-outline-dark;
}
