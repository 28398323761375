.board {
    position: absolute;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    user-select: none;

    touch-action: none;
}
